.st-Logo {
  display: table;
  position: relative;
  text-decoration: none;

  @include mq-up(lg) {
    line-height: 1;
  }

  &:hover {
    text-decoration: none;
  }
}

.st-Logo_Main {
  display: table-cell;
  vertical-align: middle;
  width: 118px;
  min-width: 118px;

  @include mq-up(lg) {
    width: 159px;
    min-width: 159px;
  }

  html[lang="zh-cn"] & {
    width: 173px;
    height: 40px;
    min-width: 173px;

    @include mq-up(lg) {
      width: 236px;
      height: 34px;
      min-width: 236px;
    }
  }
}

.st-Logo_Main img {
  width: 118px;
  height: 40px;

  @include mq-up(lg) {
    width: 159px;
    height: 34px;
  }

  html[lang="zh-cn"] & {
    width: 173px;
    height: 40px;

    @include mq-up(lg) {
      width: 236px;
      height: 34px;
    }
  }
}

.st-Logo_Sub {
  display: table-cell;
  vertical-align: bottom;
  position: relative;
  right: -13px;
  bottom: 7px;
  font-size: rem(9);
  line-height: 1.2;
  color: #666;

  @include mq-up(md) {
    bottom: 5px;
    font-size: rem(13);
  }

  @include mq-up(lg) {
    vertical-align: middle;
    right: -18px;
    bottom: 0;
    z-index: 1;
    padding-right: 34px;
    font-size: rem(15);
  }
}
