.st-GlobalSiteNav {
  line-height: 1.25;
  background-color: #616161;

  @include mq-up(lg) {
    height: 85px;
    line-height: 85px;
    background-color: transparent;

    .st-GlobalNav-categorySecond &,
    .st-GlobalNav-divition & {
      display: none;
    }
  }
}

.st-GlobalSiteNav_SubArea {
  display: none;

  @include mq-up(lg) {
    display: block;
    position: absolute;
    top: 85px;
    right: auto;
    right: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
    height: auto;
    margin: auto;
    padding-bottom: 40px;
    text-align: left;
    background-color: rgba(#4f4f4f, 0.96);
  }

  &[aria-hidden="false"] {
    display: block;

    @include mq-up(lg) {
      top: 85px;
    }
  }
}

.st-GlobalSiteNav_List {
  padding-left: 0;
  font-size: 0;
  list-style-type: none;
}

.st-GlobalSiteNav_Item {
  @include mq-up(lg) {
    display: inline-block;
    vertical-align: bottom;
    font-size: 1rem;
    line-height: 1;
  }
}

.st-GlobalSiteNav_Link {
  display: block;
  padding: 16px 15px;
  text-decoration: none;
  font-size: rem(16);
  font-weight: 600;
  border-bottom: 1px solid #747474;
  background-color: #4f4f4f;
  color: #fff;
  transition-duration: $transition-duration;

  @include mq-up(lg) {
    display: inline-block;
    vertical-align: bottom;
    position: relative;
    padding: 14px 17px;
    font-size: rem(15);
    border-bottom: 0;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    color: #000;

    &.st-GlobalSiteNav_Link-current::before,
    &::before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;
      width: 0;
      height: 1px;
      margin: auto;
      border-top: 0 solid #df0523;
      transition-duration: $transition-duration;
      transition-property: width;
    }

    &.st-GlobalSiteNav_Link-current::before {
      width: 100%;
      border-top-width: 1px;
    }

    &[aria-expanded="true"]::before {
      width: 100%;
      border-top-width: 1px;
    }

    &:hover {
      color: #df0523;
    }

    &:focus {
    }

    // クリックした時はアウトラインを非表示にする。
    &:focus:not(.focus-ring) {
      outline-width: 0;
    }
  }

  html[lang="ja"] &,
  html[lang="zh-cn"] & {
    font-weight: 500;
  }

  &:hover {
    text-decoration: none;
  }
}

.st-GlobalSiteNav_LinkIcon {
  display: none;

  .st-GlobalSiteNav_Link-hasChildren & {
    display: inline-block;

    @include mq-up(lg) {
      display: none;
    }
  }
}

.st-GlobalSiteNav_Link-hasChildren {
  position: relative;
}

.st-GlobalSiteNav_LinkIcon {
  position: absolute;
  top: 0;
  right: 19px;
  bottom: 0;
  width: 15px;
  height: 24px;
  margin: auto;

  &::before {
    @include icon;

    content: "#{$icon-chevron-down}";
    font-size: 1.5em;
    color: #b6b6b6;

    .st-GlobalSiteNav_Link-hasChildren[aria-expanded="true"] & {
      transform: rotate(180deg);
    }
  }
}

.st-GlobalSiteNav_DivisionTitle {
  display: block;
  padding: 16px 15px;
  text-decoration: none;
  font-size: rem(16);
  font-weight: 600;
  background-color: #313131;
  color: #fff;

  @include mq-up(lg) {
    display: none;
  }

  html[lang="ja"] &,
  html[lang="zh-cn"] & {
    font-weight: 500;
  }
}

.st-GlobalSiteNav_SubLinkTop {
  display: block;
  padding: 16px 15px;
  text-decoration: none;
  font-size: rem(16);
  background-color: rgba(#747474, 0.9);
  color: #fff;

  @include mq-up(lg) {
    height: 64px;
    padding: 0;
    font-size: rem(22);
    font-weight: 600;
    line-height: 64px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.st-GlobalSiteNav_SubLinkTopInner {
  max-width: $max-width;
  margin: auto;

  @include mq-up(lg) {
    padding: 0 15px;
  }
}

.st-GlobalSiteNav_SubLinkTopIcon::before {
  @include mq-up(lg) {
    @include icon;

    content: "#{$icon-chevron-right}";
    font-size: 1.3em;
  }
}

.st-GlobalSiteNav_SubAreaInner {
  max-width: $max-width;
  margin: auto;
  padding: 0 15px;

  @include mq-up(lg) {
    margin-top: 30px;
    line-height: 1.4;
  }

  .st-GlobalSiteNav_SubLinkTop + & {
    margin-top: -1px;

    @include mq-up(lg) {
      margin-top: 30px;
    }
  }
}

.st-GlobalSiteNav_GlobalLink {
  display: block;
  padding: 30px 15px 30px 12px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #4f4f4f;
  color: #bcbcbc;

  @include mq-up(lg) {
    display: none;
  }
}

.st-GlobalSiteNav_GlobalLinkIcon {
  &::before {
    @include icon;

    content: "#{$icon-sphere}";
    position: relative;
    top: -0.1em;
    font-size: 1.3em;
  }
}

.st-GlobalSiteNav_SubLinkTitle {
  display: block;
  padding: 16px 0;
  text-decoration: none;
  font-size: rem(17);
  font-weight: 600;
  color: #fff;

  @include mq-up(lg) {
    margin-bottom: 18px;
    padding: 0;
    font-size: rem(17);
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  &:not(:first-child) {
    border-top: 1px solid #727272;

    @include mq-up(lg) {
      margin-top: 60px;
      border-top: 0;
    }
  }

  .st-Grid_Item:not(:first-of-type) & {
    border-top: 1px solid #727272;

    @include mq-up(lg) {
      border-top: 0;
    }
  }
}

.st-GlobalSiteNav_SubLinkTitleIcon {
  &::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    font-size: 1.3em;
  }
}

.st-GlobalSiteNav_SubList {
  padding-left: 0;
  list-style-type: none;

  @include mq-up(lg) {
    .st-GlobalSiteNav_Link + .st-GlobalSiteNav_SubArea & {
      display: block;
      margin-left: -30px;
      padding-left: 0;
      font-size: 0;
      list-style-type: none;
    }
  }
}

.st-GlobalSiteNav_SubItem {
  position: relative;
  font-size: rem(16);

  @include mq-up(lg) {
    .st-GlobalSiteNav_Link + .st-GlobalSiteNav_SubArea & {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      padding-left: 30px;
      font-size: medium;
      font-size: 1rem;
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 1.4em;
    left: 0;
    width: 0;
    height: 0;
    border-width: 5px 0 5px 8px;
    border-style: solid;
    border-color: transparent transparent transparent #df0523;

    @include mq-up(lg) {
      top: 0.9em;
      left: calc(1em + 16px);
    }
  }
}

.st-GlobalSiteNav_SubLink {
  display: block;
  padding: 16px 0 16px 18px;
  text-decoration: none;
  border-top: 1px solid #727272;
  color: #ebebeb;

  @include mq-up(lg) {
    padding: 8px 1em;
    font-size: rem(15);
    line-height: 1.4;
    border-top: 0;

    &:hover {
      text-decoration: underline;
    }
  }
}

.st-GlobalSiteNav_SubLinkIconExternal,
.st-GlobalSiteNav_SubLinkIconInternal {
  &::before {
    @include icon;

    margin-top: -0.1em;
    margin-left: 4px;
    font-size: 1.3em;
    color: #ccc;
  }
}

.st-GlobalSiteNav_SubLinkIconExternal {
  &::before {
    content: "#{$icon-external-link}";
  }
}

.st-GlobalSiteNav_SubLinkIconInternal {
  &::before {
    content: "#{$icon-filter_none}";
  }
}
