.st-CompanyButton {
  display: none;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  transition-duration: $transition-duration;
  appearance: none;

  @include mq-up(lg) {
    display: inline-block;
    position: relative;
    top: -3px;
    z-index: 1;
    width: auto;
    height: auto;
    padding-left: 30px;
    font-size: rem(12);
    line-height: 1;
    color: #666;

    &:hover {
      color: #df0523;
    }

    &::before {
      content: "About Kyocera";
      display: inline-block;
      vertical-align: middle;
      font-size: rem(12);

      [lang="ja"] & {
        content: "会社情報";
      }

      [lang="zh-cn"] & {
        content: "公司信息";
      }
    }

    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 1px;
      height: 19px;
      margin-right: 14px;
      margin-left: 20px;
      background-color: #e6e6e6;
    }

    &:active {
      &::before {
        position: relative;
      }
    }
  }

  &:focus {
  }

  // クリックした時はアウトラインを非表示にする。
  &:focus:not(.focus-ring) {
    outline-width: 0;
  }
}

.st-CompanyButton_Icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 40px;
  margin: auto;

  @include mq-up(md) {
    width: 56px;
    height: 56px;
  }

  @include mq-up(lg) {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -110px;
    width: auto;
    width: 19px;
    height: auto;
    height: 19px;
    margin: auto;

    [lang="ja"] & {
      left: -75px;
    }

    [lang="zh-cn"] & {
      left: -75px;
    }
  }

  &::before {
    @include icon;

    content: "#{$icon-arrow_drop_down}";
    position: relative;
    top: 0.1em;
    font-size: 2.15rem;

    @include mq-up(md) {
      top: 0.18em;
      font-size: 2.6rem;
    }

    @include mq-up(lg) {
      top: 0.1rem;
      font-size: rem(18);
      color: #df0522;

      .st-CompanyButton:hover & {
        color: currentColor;
      }
    }

    .st-CompanyButton[aria-expanded="true"] & {
      content: "#{$icon-close}";

      @include mq-up(lg) {
        content: "#{$icon-arrow_drop_down}";
      }
    }
  }
}
