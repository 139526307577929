.st-ToggleButton {
  float: left;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  appearance: none;

  @include mq-up(md) {
    width: 56px;
    height: 56px;
  }

  @include mq-up(lg) {
    display: none;
  }

  &:focus {
    outline: 0;
  }
}

.st-ToggleButton_Icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 40px;
  margin: auto;

  @include mq-up(md) {
    width: 56px;
    height: 56px;
  }

  &::before {
    @include icon;

    content: "#{$icon-menu}";
    position: relative;
    top: 0.1em;
    font-size: 2.15rem;

    @include mq-up(md) {
      top: 0.18em;
      font-size: 2.6rem;
    }

    .st-ToggleButton[aria-expanded="true"] & {
      content: "#{$icon-close}";
    }
  }
}
