.st-SearchForm {
  clear: both;
  display: none;
  position: fixed;
  top: 65px;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 78px;
  padding: 19px 15px;
  background-color: rgba(#4f4f4f, 0.9);

  @include mq-up(lg) {
    position: absolute;
    top: 84px;
    height: 98px;
    padding: 20px 15px 20px;

    &[aria-hidden="false"] {
      border-top: 1px solid #e6e6e6;
    }
  }
}

.st-SearchForm_Form {
  position: relative;
  max-width: 700px;
  margin: auto;

  @include mq-up(lg) {
    padding-right: 4em;
  }
}

.st-SearchForm_Field {
  position: relative;
}

.st-SearchForm_Input {
  vertical-align: middle;
  width: 100%;
  height: 40px;
  padding: 0 1em 0 35px;
  font-size: rem(16);
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  background-color: #fff;
  appearance: none;

  @include mq-up(lg) {
    height: 58px;
    padding-left: 55px;
    font-size: rem(24);
  }

  &[type="search"] {
    box-sizing: border-box;
    appearance: none;
  }

  &::placeholder {
    font-size: rem(15);
    color: #a3a3a3;

    @include mq-up(lg) {
      font-size: rem(24);
    }
  }

  &:focus {
    outline: 0;
  }
}

.st-SearchForm_Icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 6px;
  width: 20px;
  height: 20px;
  margin: auto;

  @include mq-up(lg) {
    left: 10px;
    width: 38px;
    height: 38px;
  }

  &::before {
    @include icon;

    content: "#{$icon-search}";
    position: relative;
    top: -0.1em;
    font-size: 1.8em;
    color: #555;

    @include mq-up(lg) {
      top: 0;
      font-size: 2.4rem;
    }
  }
}

.st-SearchForm_Close {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 20px;
  height: 20px;
  margin: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
  appearance: none;

  @include mq-up(lg) {
    right: 40px;
    width: 35px;
    height: 35px;
  }

  &:focus {
    outline: 0;
    box-shadow: $form-focus-box-shadow;
  }
}

.st-SearchForm_CloseIcon {
  &::before {
    @include icon;

    content: "#{$icon-close}";
    position: relative;
    top: -0.1em;
    font-size: 1.3em;
    color: #565656;

    @include mq-up(lg) {
      top: 0;
      font-size: 2.2em;
    }
  }
}

.st-SearchForm_Submit {
  display: none;
}
