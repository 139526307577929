/* #base
   -------------------------------------------------------------------------- */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: ((map-get($font-size, sm) / 16) * 100%);
  line-height: 1.6;
}

body {
  word-wrap: break-word;
  overflow-wrap: break-word;
  background-color: #fff;
  color: $color-text;
  font-kerning: normal;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

/**
 * フルードイメージにします。
 */
img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

/* imgとonject要素でSVGをフルードイメージにします。 */
[src$=".svg"],
[data$=".svg"] {
  width: 100%; /* IE対応 */
}

/* IE10以降`input[type="text"]`の入力内容の消去を非表示にします。 */
input::-ms-clear {
  visibility: hidden;
}

/* IE10以降の`input[type="password"]`のマスクの一時的解除を非表示にします。 */
input::-ms-reveal {
  visibility: hidden;
}

/* Normalize.cssをリセットします。 */
figure {
  margin: 0;
}

/* ブロック要素の上下のマージンをリセットします。 */
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
fieldset,
figure,
pre {
  margin-top: 0;
  margin-bottom: 0;
}
