html[lang="ja"] {
  .st-GlobalFooter {
    font-family: "Open Sans", "Subset Noto Sans JP", Arial, Helvetica, sans-serif;
  }
}

html[lang="zh-cn"] {
  .st-GlobalFooter {
    font-family: "Open Sans", "Subset Noto Sans SC", Arial, Helvetica, sans-serif;
  }
}

.st-GlobalFooter {
  border-top: 1px solid #e6e6e6;
}

.st-GlobalFooter_Inner {
  @include clearfix;

  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 25px 0 0;

  @include mq-up(md) {
    padding: 20px 15px;
  }
}

.st-GlobalFooter_NavArea {
  margin-bottom: 20px;
  padding: 0 15px;

  @include mq-up(md) {
    float: left;
    margin-bottom: 0;
    padding: 0;
  }
}

.st-GlobalFooter_Nav {
  display: block;
  padding-left: 0;
  text-align: center;
  font-size: 0;
  list-style-type: none;
}

.st-GlobalFooter_NavItem {
  display: inline-block;
  vertical-align: middle;
  padding: 8px 0;
  font-size: rem(12);

  @include mq-up(md) {
    padding: 3px 0 2px;
    font-size: rem(14);
  }

  & + &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 1rem;
    margin: 0 8px;
    border-left: 1px solid #dadada;

    @include mq-up(md) {
      margin: 0 20px;
    }
  }
}

.st-GlobalFooter_NavLink {
  text-decoration: none;
  color: #666;

  &:hover {
    text-decoration: underline;
    color: $color-link-hover;
    transition-duration: $transition-duration;
  }

  & > .st-Icon {
    position: relative;
    top: -0.1em;
    margin-left: 6px;
  }
}

.st-GlobalFooter_NavIconExternal {
  &::before {
    @include icon;

    content: "#{$icon-external-link}";
    margin-left: 3px;
    font-size: 1.2em;
    color: #777;

    .st-GlobalFooter_NavLink:hover & {
      color: currentColor;
    }
  }
}

.st-GlobalFooter_CopyrightArea {
  margin-bottom: 20px;
  text-align: center;

  @include mq-up(md) {
    float: right;
    margin-bottom: 0;
  }
}

.st-GlobalFooter_Copyright {
  font-size: rem(13);
  font-weight: 300;
  line-height: 1;
}

.st-GlobalFooter_PageTop {
  display: block;
  padding: 0 0 4px;
  text-align: center;
  background-color: #818181;
  transition-duration: $transition-duration;

  @include mq-up(md) {
    position: fixed;
    right: 40px;
    bottom: 56px;
    width: 56px;
    height: 56px;
    padding: 0;
    border-radius: 50%;
    background-color: rgba(129, 129, 129, 0.7);
    transition-duration: 2s;
    transition-property: overflow, visibility, opacity;

    &[aria-hidden="true"] {
      visibility: hidden;
      overflow: hidden;
      height: 0;
      opacity: 0;
    }

    &[aria-hidden="false"] {
      visibility: visible;
      overflow: visible;
      height: 56px;
      opacity: 1;
    }

    &.st-GlobalFooter_PageTop-static {
      position: fixed;
      right: 40px;
      bottom: 100px;
      width: 56px;
      height: 56px;
    }
  }

  @include mq-up(lg) {
    &:hover {
      opacity: 0.6;
    }
  }
}

.st-GlobalFooter_PageTopIcon {
  font-size: 1.9rem;
  line-height: 1.4;
  color: #fff;

  @include mq-up(md) {
    height: 56px;
    margin-top: -0.1em;
    font-size: 1.6rem;
    line-height: 56px;
  }
}
