.st-SearchButton {
  float: left;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  transition-duration: $transition-duration;
  appearance: none;

  @include mq-up(md) {
    width: 56px;
    height: 56px;
  }

  @include mq-up(lg) {
    display: inline-block;
    float: none;
    position: relative;
    top: -3px;
    z-index: 1;
    width: auto;
    height: auto;
    padding-left: 30px;
    font-size: rem(12);
    line-height: 1;
    color: #666;

    &:hover {
      color: #df0523;
    }

    &::after {
      content: "Search";
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      font-size: rem(12);
    }

    &:active {
      &::after {
        position: relative;
      }
    }
  }

  &:focus {
  }

  // クリックした時はアウトラインを非表示にする。
  &:focus:not(.focus-ring) {
    outline-width: 0;
  }

  // FireFoxのoutlineを表示させる。
  @-moz-document url-prefix() {
    &:focus {
      outline: 1px dotted #212121;
    }
  }
}

.st-SearchButton_Icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 40px;
  margin: auto;

  @include mq-up(md) {
    width: 56px;
    height: 56px;
  }

  @include mq-up(lg) {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -65px;
    width: auto;
    width: 19px;
    height: auto;
    height: 19px;
    margin: auto;
  }

  &::before {
    @include icon;

    content: "#{$icon-search}";
    position: relative;
    top: 0.1em;
    font-size: 2.15rem;

    @include mq-up(md) {
      top: 0.18em;
      font-size: 2.6rem;
    }

    @include mq-up(lg) {
      top: -0.15em;
      font-size: 1.95rem;
      color: #333;

      .st-SearchButton:hover & {
        color: currentColor;
      }
    }

    .st-SearchButton[aria-expanded="true"] & {
      content: "#{$icon-close}";

      @include mq-up(lg) {
        content: "#{$icon-search}";
      }
    }
  }
}
