.st-GlobalLink {
  display: none;

  @include mq-up(lg) {
    display: inline-block;
    position: relative;
    top: -2px;
    left: 0;
    height: 19px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: rem(12);
    line-height: 1;
    color: #666;
    transition-duration: $transition-duration;

    &:hover {
      color: $color-link-hover;
    }

    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 1px;
      height: 19px;
      margin-right: 14px;
      margin-left: 14px;
      background-color: #e6e6e6;
    }
  }
}

.st-GlobalLink_Icon {
  display: inline-block;

  &::before {
    @include mq-up(lg) {
      @include icon;

      content: "#{$icon-sphere}";
      font-size: 2.6em;
      color: #333;

      .st-GlobalLink:hover & {
        color: currentColor;
      }
    }
  }
}
