html[lang="ja"] {
  .st-GlobalHeader_Logo {
    .st-Logo_Sub {
      font-family: "Open Sans", "Subset Noto Sans JP", Arial, Helvetica, sans-serif;
    }
  }

  .st-GlobalNav_Area {
    .st-GlobalSiteNav:last-child,
    .st-GlobalCommonNav,
    .st-DropGlovalNav {
      font-family: "Open Sans", "Subset Noto Sans JP", Arial, Helvetica, sans-serif;
    }
  }

  .st-GlobalHeader_Buttons {
    font-family: "Open Sans", "Subset Noto Sans JP", Arial, Helvetica, sans-serif;
  }
}

html[lang="zh-cn"] {
  .st-GlobalHeader_Logo {
    .st-Logo_Sub {
      font-family: "Open Sans", "Subset Noto Sans SC", Arial, Helvetica, sans-serif;
    }
  }

  .st-GlobalNav_Area {
    .st-GlobalSiteNav:last-child,
    .st-GlobalCommonNav,
    .st-DropGlovalNav {
      font-family: "Open Sans", "Subset Noto Sans SC", Arial, Helvetica, sans-serif;
    }
  }

  .st-GlobalHeader_Buttons {
    font-family: "Open Sans", "Subset Noto Sans SC", Arial, Helvetica, sans-serif;
  }
}

.st-GlobalHeader {
  @include clearfix;

  position: relative;
  border-bottom: 1px solid #e6e6e6;
}

.st-GlobalHeader_Inner {
  max-width: $max-width;
  margin: auto;
}

.st-GlobalHeader_CloseButtonArea {
  position: relative;
  max-width: $max-width;
  margin: auto;
}

.st-GlobalHeader_Logo {
  display: block;
  float: left;
  width: calc(100% - 85px);
  height: 64px;
  padding: 12px 0 12px 12px;

  @include mq-up(md) {
    width: calc(100% - 112px);
    height: 64px;
    padding-left: 15px;
  }

  @include mq-up(lg) {
    float: left;
    width: 266px;
    height: 84px;
    padding: 25px 0 25px 17px;
    line-height: 84px;

    html[lang="zh-cn"] & {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}

.st-GlobalHeader_Buttons {
  @include clearfix;

  float: right;
  width: 85px;
  height: 64px;
  // 5pxずつ右上に移動（アイコンの余白分）
  padding-top: 18px;
  padding-right: 5px;

  @include mq-up(md) {
    width: 112px;
    height: 64px;
    padding-top: 5px;
    padding-right: 0;
  }

  @include mq-up(lg) {
    float: right;
    position: relative;
    width: 234px;
    height: 84px;
    padding-top: 0;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    text-align: right;
    line-height: (84 / 2) * 3px;

    .st-GlobalNav[aria-hidden="false"] + & {
      position: static;
    }

    &.st-GlobalHeader_Buttons-devision {
      width: 400px;
    }
  }

  /* TODO: 必要かどうか確認する */
  &.st-GlobalHeader_Buttons-fixed {
    position: fixed;
    top: 0;
    right: 0;
  }
}

.st-GlobalHeader_nav {
  clear: both;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.st-GlobalHeader_StatementArea {
  display: none;

  @include mq-up(lg) {
    display: block;
    position: absolute;
    top: 10px;
    right: 0;
    width: 100%;
  }
}

.st-GlobalHeader_Statement {
  display: none;

  @include mq-up(lg) {
    display: block;
    max-width: $max-width;
    margin: auto;
    padding-right: 15px;
    text-align: right;
  }
}

/* 詳細度調整のためセレクタを追加 */
.st-GlobalHeader_StatementImage.st-GlobalHeader_StatementImage {
  width: 185px;
  height: 12px;

  html[lang="zh-cn"] &[src="/_assets/img/common/statement_ch.svg"] {
    width: 273px;
    height: 12px;
  }
}
