.st-GlobalNav {
  @include mq-up(lg) {
    float: left;
    width: auto;
    width: calc(100% - 266px - 234px);
    min-height: auto;
    height: auto;
    height: 84px;
    margin: auto;
    padding-bottom: 0;
    text-align: center;
    background-color: transparent;
    background-color: #fff;

    &.st-GlobalNav-divition {
      height: 0;
    }
  }

  &[aria-hidden="true"] {
    display: none;
  }

  &[aria-hidden="false"] {
    clear: both;
    display: block;
    overflow-y: scroll;
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    padding-bottom: 100px;
  }

  &.st-GlobalNav-categorySecond,
  &.st-GlobalNav-divition {
    background-color: #fff;
  }
}

.st-GlobalNav_Area {
  display: none;
  position: fixed;
  top: 65px;
  left: 0;
  z-index: 1000;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  padding-bottom: 62.5vw;

  @include mq-up(lg) {
    position: static;
    padding-bottom: 0;
    background-color: transparent;

    &[aria-hidden="false"] {
      display: inline;
      vertical-align: middle;
      overflow: hidden;
      width: auto;
      min-height: auto;
      height: auto;
      margin: auto;
      padding-bottom: 0;
      text-align: center;
      background-color: transparent;
    }
  }

  .st-GlobalNav-categoryFirst & {
    background-color: #4f4f4f;
  }

  .st-GlobalNav-categorySecond &,
  .st-GlobalNav-divition & {
    background-color: #fff;
  }

  &[aria-hidden="false"] {
    .st-GlobalNav-divition & {
      background-color: #fff;
    }
  }
}

.st-GlobalNav_NavClose {
  display: none;

  @include mq-up(lg) {
    display: none;

    &[aria-hidden="false"] {
      display: block;
      position: absolute;
      // 85 px（グローバルナビ） + ボタンの高さ÷2
      top: 85 + (32 / 2) * 1px;
      right: 15px;
      z-index: 10000;
      width: 32px;
      height: 32px;
      margin: 0;
      padding: 0;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: transparent;
      appearance: none;
    }
  }
}

.st-GlobalNav_NavCloseIcon {
  display: block;
  position: relative;
  top: -1px;
  left: -1px;
  width: 32px;
  height: 32px;

  // IE9以上
  :root & {
    top: -0.5px\0;
    left: -0.5px\0;
  }

  &::before {
    @include icon;

    content: "#{$icon-close}";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 20.8px;
    height: 20.8px;
    margin: auto;
    font-size: 1.3em;
    color: #fff;
  }
}

.st-GlobalNav_SearchClose {
  display: none;

  @include mq-up(lg) {
    display: none;

    &[aria-hidden="false"] {
      display: block;
      position: absolute;
      // グローバルヘッダー + 検索エリアの1/2 - アイコンの1/2
      top: 85 + (98 / 2) - (32 / 2) + 1px;
      right: 15px;
      z-index: 10000;
      width: 32px;
      height: 32px;
      margin: 0;
      padding: 0;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: transparent;
      opacity: 1;
      appearance: none;
    }
  }

  &:focus:not(.focus-ring) {
    outline-width: 0;
  }

  // // FireFoxのoutlineを表示させる。
  @-moz-document url-prefix() {
    &:focus {
      outline: 1px dotted #212121;
    }
  }
}

.st-GlobalNav_SearchCloseIcon {
  display: block;
  position: relative;
  top: -1px;
  left: -1px;
  width: 32px;
  height: 32px;

  // IE9以上
  :root & {
    top: -0.5px\0;
    left: -0.5px\0;
  }

  &::before {
    @include icon;

    content: "#{$icon-close}";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 20.8px;
    height: 20.8px;
    margin: auto;
    font-size: 1.3em;
    color: #fff;
  }
}
