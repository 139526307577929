.st-Title {
  margin: 15px 0;
  font-size: rem(18);
  font-weight: 300;
  line-height: 1.2;
  color: #000;

  @include mq-up(md) {
    font-size: rem(22);
  }

  @include mq-up(lg) {
    margin: 25px 0 15px;
  }

  html[lang="ja"] & {
    font-weight: 200;
  }
}
