// default breakpoint
$default-breakpoint: "lg" !default;

// default wrapper width
$max-width: 1200px !default;

$font-size: (
  "sm": 16,
) !default;

$font-family-open-sans: "Open Sans", "Keyocera Open Sans", "Helvetica Neue", Helvetica, sans-serif !default;

$color-brand: #df0523 !default;
$color-text: #000;
$color-link: #0071c8 !default;
$color-link-hover: $color-brand !default;
$transition-duration: 0.3s !default;

$form-border: 1px solid #767676 !default;
$form-border-radius: 3px !default;
$form-transition-duration: 0.3s !default;
$form-transition-property: border-color, box-shadow, background-color !default;
$form-focus-border-color: #1589ee !default;
$form-focus-box-shadow: 0 0 5px #1589ee !default;
$form-placeholder-color: #767676 !default;
$form-disabled-cursor: not-allowed !default;
$form-disabled-opacity: 0.5 !default;
$form-disabled-background-color: #ddd !default;
