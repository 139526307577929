.st-Breadcrumb {
  display: none;

  @include mq-up(lg) {
    display: block;
    margin: 10px 0 10px;
    font-size: rem(12);
    line-height: 1;
  }
}

.st-Breadcrumb_List {
  @include clearfix;

  padding-left: 0;
  list-style-type: none;
}

.st-Breadcrumb_Item {
  float: left;
  color: #666;

  &:not(:first-of-type)::before {
    @include icon;

    content: "#{$icon-chevron-right}";
    display: inline-block;
    margin: 0 8px;
  }
}

.st-Breadcrumb_Link {
  text-decoration: none;
  color: #666;

  &:hover {
    text-decoration: underline;
    color: $color-link-hover;
    transition-duration: $transition-duration;
  }
}
