.st-DropGlovalNav {
  background-color: #fff;

  @include mq-up(lg) {
    position: absolute;
    top: 85px;
    right: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
    height: 98px;
    margin: auto;
    padding: 20px 15px;
    text-align: left;
    background-color: rgba(79, 79, 79, 0.9);

    &[aria-hidden="false"] {
      top: 85px;
    }

    .st-GlobalNav-divition &[aria-hidden="false"] {
      display: none;
    }
  }
}

.st-DropGlovalNav_List {
  display: none;
  padding: 0 15px;

  @include mq-up(lg) {
    display: block;
    max-width: $max-width;
    margin: auto;
    padding: 20px 15px;
    text-align: center;
    font-size: 0;
  }
}

.st-DropGlobalNav_Item {
  @include mq-up(lg) {
    display: inline-block;
    margin: 0 60px 0 0;

    &:last-child {
      margin-right: 0;
    }

    &.st-DropGlobalNav_Item-Global {
      display: none;
    }
  }
}
// About Kyocera
.st-DropGlobalNav_LinkTop {
  display: block;
  position: relative;
  padding: 16px 15px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 1px solid #dadada;
  background-color: #fff;
  color: #444;

  @include mq-up(lg) {
    display: none;
  }

  &[aria-expanded="true"] {
    border-bottom: 0;
  }
}

.st-GlobalSiteNav_SubLinkTopIcon {
  .st-DropGlobalNav_LinkTop-sp & {
    position: absolute;
    top: 0;
    right: 19px;
    bottom: 0;
    width: 15px;
    height: 24px;
    margin: auto;

    &::before {
      @include icon;

      content: "#{$icon-chevron-down}";
      font-size: 1.5em;
      color: currentColor;
    }
  }

  .st-DropGlobalNav_LinkTop-sp[aria-expanded="true"] & {
    &::before {
      transform: rotate(180deg);
    }
  }
}

.st-DropGlobalNav_Link {
  display: block;
  position: relative;
  padding: 16px 0;
  text-decoration: none;
  font-size: 1rem;
  border-bottom: 1px solid #dadada;
  background-color: #fff;
  color: #444;

  @include mq-up(lg) {
    display: block;
    padding: 0;
    text-decoration: none;
    font-size: rem(15);
    border: 0;
    background: transparent;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }

    &:not(:first-child) {
      margin-top: 60px;
      border-top: 0;
    }
  }

  .st-DropGlobalNav_Item:first-child & {
    border-top: 1px solid #dadada;

    @include mq-up(lg) {
      border-top: 0;
    }
  }

  &[aria-expanded="true"] {
    border-bottom: 0;
  }

  .st-DropGlobalNav_Item-Global & {
    padding: 30px 0;
    text-transform: uppercase;
    font-size: rem(15);
    border-bottom: 0;
    color: #8d8d8d;
  }
}

.st-GlobalSiteNav_SubLinkTitleIcon {
  .st-DropGlobalNav_Link & {
    display: none;

    @include mq-up(lg) {
      display: inline;
    }
  }
}

.st-GlobalNav_DropGnavClose {
  display: none;

  @include mq-up(lg) {
    display: none;

    &[aria-hidden="false"] {
      display: block;
      position: absolute;
      top: 119px;
      right: 15px;
      z-index: 10000;
      width: 32px;
      height: 32px;
      margin: 0;
      padding: 0;
      text-align: center;
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: transparent;
      appearance: none;
    }
  }
}

.st-GlobalNav_DropGnavCloseIcon {
  display: block;
  position: relative;
  top: -1px;
  left: -1px;
  width: 32px;
  height: 32px;

  // IE9以上
  :root & {
    top: -0.5px\0;
    left: -0.5px\0;
  }

  &::before {
    @include icon;

    content: "#{$icon-close}";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 20.8px;
    height: 20.8px;
    margin: auto;
    font-size: 1.3em;
    color: #fff;
  }
}

.st-GlobalSiteNav_SubLinkTitleIcon {
  display: none;

  @include mq-up(lg) {
    display: inline-block;
  }
  .st-DropGlobalNav_Link & {
    @include mq-up(lg) {
      display: none;
    }
  }
}

span.st-DropGlobalNav_LinkIcon-pc {
  display: none;

  @include mq-up(lg) {
    display: inline;

    &::before {
      @include icon;

      content: "#{$icon-arrow_drop_down}";
      margin: -0.25rem 0 0;
      font-size: rem(20);
      color: #fff;
      transform: rotate(-90deg);
    }
  }
}
