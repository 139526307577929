 /* #Icon
   -------------------------------------------------------------------------- */
/*doc
---
name: Icon
category: SiteWide
tag: Icon
---

アイコンフォントを使ったアイコンを表示します。デフォルトでは`:before`擬似要素で表示されます。

```jade
p More
  span.sw-Icon.sw-Icon-linkMore(aria-hidden="true")
```
*/
@font-face {
  font-family: "template-iconfont";
  src: url('../font/template-iconfont.eot');
  src: url('../font/template-iconfont.eot?#iefix') format('eot'),
    url('../font/template-iconfont.woff') format('woff'),
    url('../font/template-iconfont.ttf') format('truetype'),
    url('../font/template-iconfont.svg#template-iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

// アイコンフォントのベーススタイルです。
@mixin icon {
  font-family: "template-iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: none;
  text-transform: none;
  speak: none;
}

// アイコンフォントを`content: "";`で表示できるように変数化しています。
// インターポレーション（`#{}`）の中に入れる必要があります。
// example: content: "#{$icon-LinkMore}";
$icon-arrow_drop_down: "\EA01" !default;
$icon-chevron-down: "\EA02" !default;
$icon-chevron-left: "\EA03" !default;
$icon-chevron-right: "\EA04" !default;
$icon-chevron-up: "\EA05" !default;
$icon-close: "\EA06" !default;
$icon-company: "\EA07" !default;
$icon-external-link: "\EA08" !default;
$icon-file-pdf: "\EA09" !default;
$icon-filter_none: "\EA0A" !default;
$icon-menu: "\EA0B" !default;
$icon-pdf: "\EA0C" !default;
$icon-search: "\EA0D" !default;
$icon-sphere: "\EA0E" !default;


/* 汎用的なアイコンフォントです。 */
.st-Icon { display: inline-block; }
.st-Icon:before { @include icon; }

.st-Icon-arrow_drop_down:before { content: "\EA01"; }
.st-Icon-chevron-down:before { content: "\EA02"; }
.st-Icon-chevron-left:before { content: "\EA03"; }
.st-Icon-chevron-right:before { content: "\EA04"; }
.st-Icon-chevron-up:before { content: "\EA05"; }
.st-Icon-close:before { content: "\EA06"; }
.st-Icon-company:before { content: "\EA07"; }
.st-Icon-external-link:before { content: "\EA08"; }
.st-Icon-file-pdf:before { content: "\EA09"; }
.st-Icon-filter_none:before { content: "\EA0A"; }
.st-Icon-menu:before { content: "\EA0B"; }
.st-Icon-pdf:before { content: "\EA0C"; }
.st-Icon-search:before { content: "\EA0D"; }
.st-Icon-sphere:before { content: "\EA0E"; }
