.st-GlobalCommonNav {
  background-color: #fff;

  @include mq-up(lg) {
    height: 85px;
    line-height: 85px;
    background-color: transparent;
  }
}

.st-GlobalCommonNav_SubArea {
  &[aria-hidden="true"] {
    display: none;
  }

  &[aria-hidden="false"] {
    display: block;
    border-bottom-color: #fff;
  }
}

.st-GlobalCommonNav_List {
  padding-left: 0;
  font-size: 0;
  list-style-type: none;
}

.st-GlobalCommonNav_Item {
  font-size: 1rem;

  @include mq-up(lg) {
    display: inline-block;
    vertical-align: bottom;
    line-height: 1;
  }
}

.st-GlobalCommonNav_Link {
  display: block;
  position: relative;
  padding: 16px 15px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  border-bottom: 1px solid #dadada;
  background-color: #fff;
  color: #444;

  @include mq-up(lg) {
    display: none;
  }

  &[aria-expanded="true"] {
    border-bottom: 0;
  }
}

.st-GlobalCommonNav_LinkIcon {
  position: absolute;
  top: 0;
  right: 19px;
  bottom: 0;
  width: 15px;
  height: 24px;
  margin: auto;

  @include mq-up(lg) {
    display: none;
  }

  &::before {
    @include icon;

    content: "#{$icon-chevron-down}";
    font-size: 1.5em;
    color: currentColor;

    .st-GlobalCommonNav_Link[aria-expanded="true"] & {
      transform: rotate(180deg);
    }
  }
}

.st-GlobalCommonNav_SubAreaInner {
  padding: 0 15px;

  @include mq-up(lg) {
    padding: 0;
  }
}

.st-GlobalCommonNav_SubList {
  padding-left: 0;
  list-style-type: none;

  @include mq-up(lg) {
    font-size: 0;
  }
}

.st-GlobalCommonNav_SubItem {
  @include mq-up(lg) {
    display: inline-block;
    vertical-align: bottom;
    font-size: 1rem;
    line-height: 1;
  }

  &:nth-last-child(1) {
    .st-GlobalCommonNav_SubLink {
      padding: 30px 0;
      text-transform: uppercase;
      font-size: rem(15);
      border-bottom: 0;
      color: #8d8d8d;

      @include mq-up(lg) {
        display: none;
      }
    }
  }
}

.st-GlobalCommonNav_SubLink {
  display: block;
  padding: 16px 0;
  text-decoration: none;
  border-bottom: 1px solid #dadada;
  color: #686868;

  @include mq-up(lg) {
    display: inline-block;
    vertical-align: bottom;
    position: relative;
    padding: 14px 17px;
    font-size: rem(15);
    font-weight: 600;
    border-bottom: 0;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    color: #000;
    transition-duration: $transition-duration;

    html[lang="ja"] &,
    html[lang="zh-cn"] & {
      font-weight: 500;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;
      width: 0;
      height: 1px;
      margin: auto;
      border-top: 0 solid #df0523;
      transition-duration: $transition-duration;
      transition-property: width;
    }

    &.st-GlobalCommonNav_SubLink-current::before {
      width: 100%;
      border-top-width: 1px;
    }

    &[aria-expanded="true"]::before {
      width: 100%;
      border-top-width: 1px;
    }

    &:hover {
      color: #df0523;
    }

    &:focus {
    }

    // クリックした時はアウトラインを非表示にする。
    &:focus:not(.focus-ring) {
      outline-width: 0;
    }
  }

  .st-GlobalCommonNav_SubItem:first-child > & {
    border-top: 1px solid #dadada;

    @include mq-up(lg) {
      border-top: 0;
    }
  }
}

.st-GlobalCommonNav_GlobalIcon {
  position: relative;
  left: -4px;
  margin-right: -2px;

  &::before {
    @include icon;

    content: "#{$icon-sphere}";
    position: relative;
    top: -0.1em;
    font-size: 1.5em;
  }
}
