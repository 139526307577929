.st-Icon-Internal,
.st-Icon-External,
.st-Icon-Pdf {
  &::before {
    @include icon();

    margin-top: -0.1em;
    font-size: 1.5em;
  }
}

.st-Icon-Internal::before {
  content: "#{$icon-filter_none}";
  color: inherit;
}

.st-Icon-External::before {
  content: "#{$icon-external-link}";
  color: inherit;
}

.st-Icon-Pdf::before {
  content: "#{$icon-file-pdf}";
  color: #df0523;
}

/* サンプル用スタイル */
.st-Icon-Sample {
  & a {
    text-decoration: none;
    color: $color-link;
    transition-duration: $transition-duration;

    &:hover {
      text-decoration: underline;
      color: $color-link-hover;
    }
  }
}
