@media print {
  body {
    // width: 172mm; /* A4縦の幅 */
    // height: 251mm; /* A4縦の高さ */
  }

  .st-GlobalHeader_Logo {
    width: 12% !important;
  }

  .st-Logo_Main,
  .st-Logo_Main img {
    // 159 * 0.628930818 = 100px
    // 236 * 0.628930818 = 148.427673048px
    width: 100px !important;
    min-width: 100px !important;

    html[lang="zh-cn"] & {
      width: 148px !important;
      min-width: 148px !important;
    }
  }

  .st-Logo_Sub {
    padding: 0 !important;
    font-size: 0.6rem !important;
  }

  .st-GlobalSiteNav_Link,
  .st-GlobalCommonNav_SubLink {
    padding: 14px 10px !important;
    font-size: 0.8rem !important;
  }

  .st-GlobalHeader_Buttons {
    width: 22% !important;
    padding-left: 0 !important;
  }

  .st-SearchButton {
    padding-left: 15px;
    font-size: 0.6rem !important;
  }

  .st-SearchButton_Icon {
    left: -45px !important;
  }

  .st-SearchButton::after,
  .st-GlobalLink {
    font-size: 0.6rem !important;
  }

  .st-SearchButton_Icon::before {
    top: 0.05em !important;
    font-size: 1.3rem !important;
  }

  .st-GlobalLink_Icon::before {
    font-size: 1.3rem !important;
  }

  .st-GlobalLink::before {
    margin: 0 !important;
    margin-left: 6px !important;
  }

  .st-GlobalHeader_StatementImage.st-GlobalHeader_StatementImage {
    transform: scale(0.8);
    transform-origin: right center;
  }

  .st-GlobalNav {
    width: calc(100% - 12% - 22%) !important;
  }

  .st-GlobalFooter_NavItem,
  .st-GlobalFooter_Copyright {
    font-size: rem(12);
  }
}
