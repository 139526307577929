@charset "UTF-8";
@import "base/variable/common/_global.scss";
@import "base/variable/template/_breakpoint.scss";
@import "base/function/_rem.scss";
@import "base/function/_str-replace.scss";
@import "base/mixin/_clearfix.scss";
@import "base/mixin/_font-face.scss";
@import "base/mixin/_mq-up.scss";
@import "base/mixin/_on-event.scss";
@import "base/mixin/_sr-only.scss";
@import "base/_normalize.scss";

// 英語フォント
@include font-face("Open Sans", "../font/OpenSans-Light", 300, normal, woff2 woff);
@include font-face("Open Sans", "../font/OpenSans-Regular", 400, normal, woff2 woff);
@include font-face("Open Sans", "../font/OpenSans-SemiBold", 600, normal, woff2 woff);
@include font-face("Open Sans", "../font/OpenSans-Bold", 700, normal, woff2 woff);

// 日本語フォント
@include font-face(
  "Subset Noto Sans JP",
  "../font/Subset_NotoSansJP-Regular",
  400,
  normal,
  woff2 woff
);
@include font-face(
  "Subset Noto Sans JP",
  "../font/Subset_NotoSansJP-Medium",
  500,
  normal,
  woff2 woff
);

// 中国語（簡体字）フォント
@include font-face(
  "Subset Noto Sans SC",
  "../font/Subset_NotoSansCJKsc-Regular",
  400,
  normal,
  woff2 woff
);
@include font-face(
  "Subset Noto Sans SC",
  "../font/Subset_NotoSansCJKsc-Medium",
  500,
  normal,
  woff2 woff
);

html[lang^="en"] {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

html[lang="ja"] {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, sans-serif;
}

html[lang="zh-cn"] {
  font-family: "Microsoft YaHei", "微软雅黑", sans-serif;
}

@import "base/_base.scss";
@import "base/_Icon.scss";

/**
 * template.css
 */
@import "Structure/_Breadcrumb.scss";
@import "Structure/_DropGnav.scss";
@import "Structure/_DropGnavButton.scss";
@import "Structure/_GlobalFooter.scss";
@import "Structure/_GlobalHeader.scss";
@import "Structure/_GlobalInner.scss";
@import "Structure/_GlobalLink.scss";
@import "Structure/_GlobalMainNav.scss";
@import "Structure/_GlobalNav.scss";
@import "Structure/_GlobalSubNav.scss";
@import "Structure/_Header.scss";
@import "Structure/_Icon.scss";
@import "Structure/_Logo.scss";
@import "Structure/_SearchButton.scss";
@import "Structure/_SearchForm.scss";
@import "Structure/_Title.scss";
@import "Structure/_ToggleButton.scss";

/**
 * 中国サイト（簡体字）
 */
html[lang="zh-cn"] {
  & .st-GlobalHeader_Logo {
    @include mq-up(lg) {
      width: 350px;
    }
  }

  & .st-GlobalNav {
    @include mq-up(lg) {
      width: calc(100% - 350px - 234px);
    }
  }
}

@import "_print.scss";
